/* Font */
// 구글 폰트의 나눔고딕
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	font-family: 'Nanum Gothic', sans-serif;
}

/* General Styles */
@import 'styles/mixin';

$maincolor: #FCB939;
$subcolor: #fda827;
$dark: #282828;
$bluegray: #9ba3b1; // Footer 폰트 색상
$font-color: #53575a; 
$bg-gray: #f7f9fd;

body, 
html {
    width: 100%;
	height: 100%;
}

.container {
	@include for-desktopWide {
		max-width: 1140px;
	}
}

body, 
p {
	color: $font-color; 
	font: 400 1rem/1.625rem;
}

h1 {
	color: $font-color;
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 3.25rem;
}

h2 {
	color: $font-color;
	font-weight: 600;
	font-size: 2rem;
	line-height: 2.5rem;
}

.h2-heading {
	@include for-tablet {
		width: 35.25rem;
		margin-right: auto;
		margin-left: auto;
	}
}

h3 {
	color: #3E3E3E;
	font-weight: 600;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

h4 {
	color: #3E3E3E;
	font-weight: 600;
	font-size: 1.5rem;
	line-height: 2rem;
}

h5 {
	color: #3E3E3E;
	font-weight: 600;
	font-size: 1.25rem;
	line-height: 1.625rem;
}

h6 {
	color: #3E3E3E;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.375rem;
}

.p-heading {
	@include for-tablet {
		width: 46rem;
		margin-right: auto;
		margin-left: auto;
	}
}

.p-large {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.p-small {
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.li-space-lg li {
	margin-bottom: 0.5rem;
}

a {
	color: $font-color;
	text-decoration: underline;
}

a:hover {
	color: $font-color;
	text-decoration: underline;
}

.no-line {
	text-decoration: none;
}

.no-line:hover {
	text-decoration: none;
}

.read-more {
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.5rem;
}

.read-more svg {
	margin-left: 0.3rem;
	font-size: 1rem;
}

.bg-gray {
	background-color: $bg-gray;
}

.btn-solid-reg {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid $maincolor;
	border-radius: 4px;
	background-color: $maincolor;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-reg:hover {
	// background-color: transparent;
	// color: $maincolor; /* needs to stay here because of the color property of a tag */
	border: 1px solid $subcolor;
	background-color: $subcolor;
	text-decoration: none;
	color: #ffffff;
}

.btn-solid-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid $maincolor;
	border-radius: 4px;
	background-color: $maincolor;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-lg:hover {
	// background-color: transparent;
	// color: $maincolor; /* needs to stay here because of the color property of a tag */
	border: 1px solid $subcolor;
	background-color: $subcolor;
	text-decoration: none;
	color: #ffffff;
}

.btn-outline-reg {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid $maincolor;
	border-radius: 4px;
	background-color: transparent;
	color: $maincolor;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-reg:hover {
	background-color: $maincolor;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #3E3E3E;
	border-radius: 4px;
	background-color: transparent;
	color: #3E3E3E;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-lg:hover {
	background-color: #3E3E3E;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #3E3E3E;
	border-radius: 4px;
	background-color: transparent;
	color: #3E3E3E;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-sm:hover {
	background-color: #3E3E3E;
	color: #ffffff;
	text-decoration: none;
}

.form-group {
	position: relative;
	margin-bottom: 1.25rem;
}

.form-control-input,
.form-control-textarea {
	width: 100%;
	padding-top: 0.875rem;
	padding-bottom: 0.875rem;
	padding-left: 1.5rem;
	border: 1px solid #cbcbd1;
	border-radius: 4px;
	background-color: #ffffff;
	font-size: 0.875rem;
	line-height: 1.5rem;
	-webkit-appearance: none; /* removes inner shadow on form inputs on ios safari */
}

.form-control-textarea {
	display: block;
	height: 14rem; /* used instead of html rows to normalize height between Chrome and IE/FF */
}

.form-control-input:focus,
.form-control-textarea:focus {
	border: 1px solid #a1a1a1;
	outline: none; /* Removes blue border on focus */
}

.form-control-input:hover,
.form-control-textarea:hover {
	border: 1px solid #a1a1a1;
}

.form-control-submit-button {
	display: inline-block;
	width: 100%;
	height: 3.25rem;
	border: 1px solid $maincolor;
	border-radius: 4px;
	background-color: $maincolor;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	cursor: pointer;
	transition: all 0.2s;
}

.form-control-submit-button:hover {
	// border: 1px solid $maincolor;
	// background-color: transparent;
	// color: $maincolor;
	border: 1px solid $subcolor;
	background-color: $subcolor;
}